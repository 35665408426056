<template>
  <modify-apply-block
    :dataFileParm="{docName: '工作经历', isDoc: true}"
    data-type="his_work"
    ref="his_work_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    block-name="工作经历"
    ref-name="his_work"
    :getTitle="getTitle"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  hisWorkRequest
} from '../../../api'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['his_work_p'].openForm) {
      this.$refs['his_work_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async getEdit () {
      if (this.workerId) {
        return await hisWorkRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await hisWorkRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await hisWorkRequest.addEdit(parm)
    },
    getTitle (data) {
      return (data.orgName || '') + ' ' + (data.jobTitle || '')
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '工作单位',
          key: 'orgName',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '担任职务',
          key: 'jobTitle',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否已结束',
          key: 'isOver',
          selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '结束时间',
          key: 'endTime',
          show: (formData) => {
            return formData.isOver === '是'
          },
          check: {
            required: true
          }
        }]
        return data
      }
    }
  }
}
</script>
